import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Sidebar Navigation",
  "type": "Navigation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Sidebar Navigation`}</strong>{` can be used for a navigation on the left side of your application.
This is usually the main navigation of your application and should be used along with
`}<a parentName="p" {...{
        "href": "/layout/header"
      }}><strong parentName="a">{`Header`}</strong></a>{` component.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true background=layout",
        "live": "true",
        "background": "layout"
      }}>{`() => {
  const [active, setActive] = React.useState(1);
  return (
    <div style={{ width: "100%" } }>
      <SidebarNavigation>
        <SidebarNavigationSection title="Pages">
          <SidebarNavigationLink
            active={active === 1}
            onClick={() => setActive(1)}
            title="Overview"
            icon={<Icons.BiHome />} />
          <SidebarNavigationLink
            active={active === 2}
            onClick={() => setActive(2)}
            title="Analytics"
            icon={<Icons.BiBarChart />} />
          <SidebarNavigationLink
            active={active === 3}
            onClick={() => setActive(3)}
            title="Ratings"
            icon={<Icons.BiStar />} />
        </SidebarNavigationSection>
        <SidebarNavigationSection title="Documentation">
          <SidebarNavigationLink
            active={active === 4}
            onClick={() => setActive(4)}
            title="Getting Started"
            icon={<Icons.BiBookOpen />} />
          <SidebarNavigationLink
            active={active === 5}
            onClick={() => setActive(5)}
            title="Support"
            icon={<Icons.BiSupport />} />
        </SidebarNavigationSection>
      </SidebarNavigation>
    </div>
  );
}
`}</code></pre>
    <h2>{`Props of `}<inlineCode parentName="h2">{`SidebarNavigation`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`fixed`}</inlineCode>{` navigation will have `}<inlineCode parentName="p">{`top: 70px`}</inlineCode>{`, so you can safely use it with `}<inlineCode parentName="p">{`fixed`}</inlineCode>{`
`}<a parentName="p" {...{
          "href": "/layout/header"
        }}><strong parentName="a">{`Header`}</strong></a>{`.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the navigation is fixed to the left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the description`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props of `}<inlineCode parentName="h2">{`SidebarNavigationSection`}</inlineCode></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(`}<strong parentName="td">{`Required`}</strong>{`) The title of the section`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the root node`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Props of `}<inlineCode parentName="h2">{`SidebarNavigationLink`}</inlineCode></h2>
    <blockquote>
      <p parentName="blockquote">{`There is additional export called `}<inlineCode parentName="p">{`SidebarNavigationLinkActiveClassName`}</inlineCode>{` which can
be used with `}<inlineCode parentName="p">{`NavLink`}</inlineCode>{` from `}<inlineCode parentName="p">{`react-router`}</inlineCode>{` or any other. It exports the class name
whenever the navigation link is active.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flag for displaying the link as active`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"a"`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title of the link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon rendered on the left`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      